<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Form Fields Templates</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/gpm/field_template/create">
          <v-icon left dark>mdi-plus</v-icon>New Template
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>

            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="users"
              :search="search"
            >
              <template v-slot:item.name="{ item }">
                <v-btn color="primary" text :to="`/gpm/users/${item.id}`">{{
                  item.name
                }}</v-btn>
              </template>
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.DefaultTemplate="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.DefaultTemplate == 1 ? "Yes" : "No" }}</v-btn
                >
              </template>

              <template v-slot:item.status="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.Enabled == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/gpm/form_field_template/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Title", value: "Name" },
        { text: "Default", value: "DefaultTemplate" },
        { text: "Created at", value: "created_at" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  methods: {
    getFieldTemplates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/fields_template`)
        .then((res) => {
          console.log(res);
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getFieldTemplates();
  },
};
</script>

<style scoped>
</style>